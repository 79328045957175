import React from "react"
import { Facebook, Twitter, Linkedin, Mail } from 'react-feather'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton } from 'react-share'

const ShareButtons = ({ url, title, description }) => (
    <div className="space-x-2">
        <FacebookShareButton url={url} quote={description}>
            <Facebook strokeWidth={1.25} className="hover:opacity-70" fill="#7ED957" size={22} />
        </FacebookShareButton>

        <LinkedinShareButton url={url} title={title} summary={description} source="https://www.dahliahomes.com/">
            <Linkedin strokeWidth={1.25} className="hover:opacity-70" fill="#7ED957" size={22} />
        </LinkedinShareButton>

        <TwitterShareButton url={url} title={description}>
            <Twitter strokeWidth={1.25} className="hover:opacity-70" fill="#7ED957" size={22} />
        </TwitterShareButton>

        <EmailShareButton url={url} subject={title} body="View This Article:" title={description}>
            <Mail strokeWidth={1.25} className="hover:opacity-70" fill="#7ED957" size={22} />
        </EmailShareButton>
    </div>
)
export default ShareButtons
